
// Desc: Main entry point for the application
// react
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// styles
import './App.css';
import './css/main.css';
import './css/Site.css';
import './css/Window.css';


// components
import NavBar from './features/NavBar/Navbar';
import { ModelProvider } from './features/Model/ModelProvider';
import Model from './features/Model/Model';
import RADManager from './features/RADHandler/RADManager';
import About from './staticpages/About'
import Examples from './staticpages/Examples'
import Language from './staticpages/Language'
import Settings from './staticpages/Settings'
import Help from './staticpages/Help'
import NoPage from './staticpages/NoPage'

import { ThemeProvider } from './features/UI/ThemeContext';

function App() {
  const ggs_ggseal = () => {
    window.open("https://my.greengeeks.com/seal/", "_blank");
  };

  return (
    <ThemeProvider >
      <BrowserRouter>
        <div className="site-container">
          <main className="main">
            <ModelProvider >
              <NavBar />
              <Routes>
                <Route path="/" element={<Model />} />
                <Route path="rad/:radId?" element={<RADManager />} />
                <Route path="about" element={<About />} />
                <Route path="examples" element={<Examples />} />
                <Route path="language" element={<Language />} />
                <Route path="settings" element={<Settings />} />
                <Route path="help" element={<Help />} />
                <Route path="*" element={<NoPage />} />
              </Routes>
            </ModelProvider>
          </main>
          <footer className="footer">
            <div className="centre-content">
              <div className="centre-text">Composed Software Ltd.</div>
              {/* <img src="path-to-your-other-image.jpg" alt="Other Image" class="footer-text-image" /> */}
            </div>
            <div className="footer-logo">
              <button onClick={ggs_ggseal} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                <img src="https://static.greengeeks.com/ggseal/Green_4.png"
                  alt="GreenGeeks Seal"
                  style={{ width: '50%', height: 'auto' }} />
              </button>            </div>
          </footer>

        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;