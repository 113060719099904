import ModelLogic from './ModelLogic';
import ModelMenuUI from './ModelMenuUI';

const ModelMenu = () => {
    return (
        <ModelLogic>
            {({
                error, showModelForm, setShowModelForm,
                saveModelToFile, handleFileSelect, resetModel, isChanged
            }) => (
                <ModelMenuUI
                    error={error}
                    showModelForm={showModelForm}
                    setShowModelForm={setShowModelForm}
                    saveModelToFile={saveModelToFile}
                    handleFileSelect={handleFileSelect}
                    resetModel={resetModel}
                    isChanged={isChanged}
                />
            )}
        </ModelLogic>
    );
};

export default ModelMenu;
